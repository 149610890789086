@forward 'quill/dist/quill.snow.css';

// quill editor always uses <ol> to show "decimal" and "disc" lists just applying special attributes to each <li> element
// at the same time quill html text model uses <ul> for "disc" lists and <ol> for "decimal" lists
// so we need to keep these styles prepared for each of them so it's aligned with the editor presentation
.ql-editor {
  padding: 0;

  ol,
  ul {
    padding-left: 1.5rem;
    list-style: none;

    li {
      padding-left: 0;
    }
  }

  ol {
    li {
      list-style-type: decimal;
    }
  }

  ul {
    li {
      list-style-type: disc;
    }
  }
}
